import {Typography} from "@mui/material";

export function Complete(props) {
    return <>
        <Typography>We hope that you can learn a lot from the custom toolkit you created!</Typography>
        <Typography>You may now close this window</Typography>
        <Typography>If the file did not open automatically, <a href={props.link}>CLICK HERE</a></Typography>
        <hr />
        <Typography>
            If you entered an email previously, expect your toolkit in your inbox soon! (if it does not arrive within a
            few moments, please double check your spam folder in case it was incorrectly marked)
        </Typography>
    </>
}