import './App.css';
import {Box, Button, Step, StepContent, StepLabel, Stepper, Typography} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import {PersonalInfo} from "./components/PersonalInfo";
import {PDFSelect} from "./components/PDFSelect";
import {RequestPDF} from "./components/RequestPDF";
import {Complete} from "./components/Complete";
import {VersionSelect} from "./components/VersionSelect";
import {ThemeProvider} from "@mui/material/styles";

import {theme} from "./lib/Constants";
import {useDispatch, useSelector} from "react-redux";
import {
    decrementActiveStep,
    incrementActiveStep,
    setLink,
    toggleErrorPDFS,
    toggleErrorType
} from "./redux/controlsSlice";

export function App() {
    //Import state to allow checking for errors before letting continue
    const controls = useSelector(state => state.controls);
    const version = useSelector(state => state.version.version);
    const pdfSelection = useSelector(state => state.pdfSelect.pdfSelection);

    const dispatch = useDispatch();

    function completedLink(link) {
        handleNext();
        dispatch(setLink(link));
    }

    function handleNext() {
        //Depending on the page, we need to do different input validation
        switch (controls.activeStep) {
            case 0:
                if (version === '') {
                    if (!controls.errors.type) {
                        dispatch(toggleErrorType());
                    }
                    return;
                }
                break;
            case 2:
                if (pdfSelection.length === 0) {
                    if (!controls.errors.pdfs) {
                        dispatch(toggleErrorPDFS());
                    }
                    return;
                }
                break;
            default:
                break;
        }

        dispatch(incrementActiveStep());
    }

    function handleBack() {
        dispatch(decrementActiveStep());
    }

    const webControls = [
        {
            label: "Select Toolkit ",
            content: <VersionSelect key={'familyVTeacherRadio'}/>,
        },
        {
            label: "Enter Your Information",
            content: <PersonalInfo key={'dataEntry'}/>,
        },
        {
            label: "Choose Skills",
            content: <PDFSelect key={'pdfSelector'}/>,
        },
        {
            label: "Submit",
            content: <RequestPDF key={'submitRequest'} completed={completedLink}/>,
        },
        {
            label: "Finish",
            content: <Complete key={'final'} link={controls.link}/>
        }
    ];

    return (
        <div style={{maxWidth: 600, alignContent: 'center', margin: 'auto'}}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Typography variant={'h4'} fontFamily={'Avenir'}>
                    Create your custom toolkit!
                </Typography>
                {controls.errors.general ?
                    <Typography>
                        Something appears to have gone wrong while creating your custom toolkit.
                        Please refresh the page and try again, or
                        if this keeps appearing - email us to let us know there is an issue
                    </Typography>
                    : <>
                        {/*Vertical Stepper*/}
                        <Stepper orientation={"vertical"} activeStep={controls.activeStep}>
                            {/*All the steps in the top bar*/}
                            {webControls.map((e, index) => (
                                <Step key={e.label}>
                                    <StepLabel><Typography fontFamily={'Avenir'}>{e.label}</Typography></StepLabel>
                                    <StepContent>
                                        {e.content}
                                        {index < 4 &&
                                            <Box sx={{mt: 2, mb: 1}}>
                                                <>
                                                    {/*Disabled if on first step or while waiting*/}
                                                    <Button color={'secondary'}
                                                            disabled={index === 0 || controls.waiting}
                                                            onClick={handleBack}>
                                                        Back
                                                    </Button>
                                                    {/*Disabled while waiting*/}
                                                    <Button color={'primary'}
                                                            disabled={index === 3 || controls.waiting}
                                                            onClick={handleNext}>
                                                        Next
                                                    </Button>
                                                </>
                                            </Box>
                                        }
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </>
                }
            </ThemeProvider>
        </div>
    );
}