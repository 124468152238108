import {
    Autocomplete,
    Box,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import {Star, StarOutline} from "@mui/icons-material";
import {colorArray, schools} from "../lib/Constants";
import {useDispatch, useSelector} from "react-redux";
import {setEmail, setName, setSchool, setSchoolValue} from "../redux/personalSlice";
import './styles.css';

export function PersonalInfo() {
    //Data Form
    //Leaving internal color array to make it easy to switch color order and arrangement in future
    const colors = colorArray;

    const personalInfo = useSelector(state => state.personal);
    const version = useSelector(state => state.version.version);
    const dispatch = useDispatch();

    const textChange = (e) => {
        dispatch(setName(e.target.value))
    }

    const emailChange = (e) => {
        dispatch(setEmail(e.target.value));
    }

    const radioChange = (e) => {
        dispatch(setSchoolValue(e.target.value));
    }

    const schoolDropdown = (e, newValue) => {
        dispatch(setSchool(newValue));
    }
    const schoolText = (e) => {
        dispatch(setSchool(e.target.value));
    }

    return <>
        <Box>
            <Grid container
                  spacing={2}
                  direction={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
            >
                <Grid item>
                    <Typography>
                        {version === 'family' ? 'Child\'s ' : ''}Name (Optional):
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField id={'name'}
                               name={'name'}
                               label={'Name'}
                               value={personalInfo.name}
                               onChange={textChange}
                               className={'text_input'}/>
                </Grid>
                <Grid item>
                    <Typography>
                        {version === 'teacher' ? 'I am a teacher at:'
                            : version === 'family' ? 'My child attends'
                                : 'Home School:'}
                    </Typography>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby={'toolkit-radio-group-label'}
                            name={'toolkit-radio-group'}
                            value={personalInfo.schoolValue}
                            onChange={radioChange}
                            className={'radio_select'}
                        >
                            <FormControlLabel sx={{color: colors[0]}}
                                              value={'public'}
                                              control={
                                                  <Radio
                                                      sx={{
                                                          color: colors[0],
                                                          '&.Mui-checked': {
                                                              color: colors[0]
                                                          }
                                                      }}
                                                      icon={<StarOutline/>}
                                                      checkedIcon={<Star/>}
                                                  />}
                                              label={'A public school in Philadelphia'}
                            />
                            <FormControlLabel sx={{color: colors[1]}}
                                              value={'private'}
                                              control={
                                                  <Radio
                                                      sx={{
                                                          color: colors[1],
                                                          '&.Mui-checked': {
                                                              color: colors[1]
                                                          }
                                                      }}
                                                      icon={<StarOutline/>}
                                                      checkedIcon={<Star/>}
                                                  />}
                                              label={'A private or parochial school in Philadelphia'}
                            />
                            <FormControlLabel sx={{color: colors[2]}}
                                              value={'outside philly'}
                                              control={
                                                  <Radio
                                                      sx={{
                                                          color: colors[2],
                                                          '&.Mui-checked': {
                                                              color: colors[2]
                                                          }
                                                      }}
                                                      icon={<StarOutline/>}
                                                      checkedIcon={<Star/>}
                                                  />}
                                              label={'A school outside of Philadelphia'}
                            />
                            <FormControlLabel sx={{color: colors[3]}}
                                              value={'no answer'}
                                              control={
                                                  <Radio
                                                      sx={{
                                                          color: colors[3],
                                                          '&.Mui-checked': {
                                                              color: colors[3]
                                                          }
                                                      }}
                                                      icon={<StarOutline/>}
                                                      checkedIcon={<Star/>}
                                                  />}
                                              label={'Decline to answer'}
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item>
                    {personalInfo.schoolValue === 'public' ?
                        <Autocomplete
                            id={'school'}
                            onChange={schoolDropdown}
                            value={personalInfo.school}
                            className={'auto_complete'}
                            renderInput={(params) =>
                                <TextField {...params}
                                            autoComplete={'off'}
                                           label={'Name of School (Optional)'}
                                />}
                            options={schools}
                            freeSolo
                        />
                        : personalInfo.schoolValue === 'private' || personalInfo.schoolValue === 'outside philly' ?
                            <TextField
                                id={'school'}
                                autoComplete={'off'}
                                label={'Name of School (Optional)'}
                                onChange={schoolText}
                                value={personalInfo.school}
                                className={'auto_complete'}
                            />
                            : <></>}
                </Grid>
                {/*<br/>*/}
                <Grid item>
                    <Typography variant={"body1"}>
                        Please email my custom toolkit to me!
                    </Typography>
                    <Typography variant={"subtitle2"}>
                        Please remember that some email providers may wrongly mark our email as spam.
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField id={'email'}
                               name={'email'}
                               type={'email'}
                               label={'Email (Optional)'}
                               value={personalInfo.email}
                               onChange={emailChange}
                               className={'text_input'}/>
                </Grid>
            </Grid>
        </Box>
    </>;
}