import {createTheme} from "@mui/material/styles";
import Avenir from './fonts/AvenirNext-Bold.woff2';

export const pdfInitialList = [
    {type: 'w', fileName: 'accepts-responsibility', displayName: 'Accepts Responsibility for Choices and Actions'},
    {type: 'm', fileName: 'can-work-independent', displayName: 'Can Work Independently'},
    {type: 'm', fileName: 'completes-work', displayName: 'Completes Work on Time'},
    {type: 'm', fileName: 'demonstrates-effort', displayName: 'Demonstrates Consistent Effort'},
    {type: 'w', fileName: 'handles-conflict', displayName: 'Handles Conflict Appropriately'},
    {type: 'w', fileName: 'listens', displayName: 'Listens and Follows Directions'},
    {type: 'w', fileName: 'makes-appropriate-movements', displayName: 'Makes Appropriate Movements between Activities'},
    {type: 'm', fileName: 'organizes-self', displayName: 'Organizes Self, Materials, and Belongings'},
    {type: 'm', fileName: 'participates', displayName: 'Participates in Group Activities'},
    {
        type: 'w',
        fileName: 'respects-rights',
        displayName: 'Respects Rights, Diversity, Feelings, and Property of Others'
    },
    {type: 'w', fileName: 'respects-school', displayName: 'Respects School Environment and Materials'},
    {type: 'm', fileName: 'shows-positive-attitude', displayName: 'Shows Positive Attitude toward Learning'},
    {type: 'm', fileName: 'strives-for-quality', displayName: 'Strives for Quality Work'},
    {type: 'w', fileName: 'works-and-plays-cooperatively', displayName: 'Works and Plays Cooperatively with Others'},
];

//Colors
export const primaryBlue = '#2475a0';
export const primaryRed = '#9e1c2b';
export const primaryYellow = '#faa930';
export const secondaryGreen = '#3a8640';
export const secondaryBlue1 = '#46a9d7';
export const secondaryBlue2 = '#173159';

export const colorArray = [primaryBlue, primaryRed, primaryYellow, secondaryGreen, secondaryBlue1, secondaryBlue2];

// https://mui.com/material-ui/customization/palette/
// https://mui.com/material-ui/customization/theming/#custom-variables
export const theme = createTheme({
    typography: {
        fontSize: 16,
        fontFamily: 'Arial, Avenir'
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
          font-family: 'Avenir';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Avenir'), local('AvenirNext-Bold'), url(${Avenir}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
        },
    },
    palette: {
        primary: {
            main: primaryBlue,
        },
        secondary: {
            main: primaryYellow,
        },
        warning: {
            main: primaryRed,
        }
    }
})

export const schools = ['Ad Prima Charter School',
    'Adaire, Alexander School',
    'Allen, Dr. Ethel School',
    'Allen, Ethan School',
    'Alliance for Progress Charter School',
    'Anderson, Add B. School',
    'Anderson, Marian Neighborhood Academy',
    'Bache-Martin School',
    'Barry, John School',
    'Barton, Clara School',
    'Belmont Elementary Charter School',
    'Bethune, Mary McLeod School',
    'Blaine, James G. School',
    'Blankenburg, R. School',
    'Bluford, Guion S. Elementary School',
    'Bracetti, Mariana Academy Charter School',
    'Bregy, Amedee F. School',
    'Bridesburg School',
    'Brown, Henry A. School',
    'Brown, Joseph H. School',
    'Bryant, William C. School',
    'Byers, Russell Charter School',
    'Carnell, Laura H. School',
    'Cassidy, Lewis C. Academic Plus School',
    'Catharine, Joseph W. School',
    'Cayuga School',
    'Childs, George W. School',
    'Columbus, Christopher Charter School',
    'Comegys, Benjamin B. School',
    'Comly, Watson School',
    'Community Academy of Philadelphia Charter School',
    'Cook-Wissahickon School',
    'Cooke, Jay School',
    'Coppin, Fanny Jackson School',
    'Cramp, William School',
    'Crossan, Kennedy C. School',
    'Crossroads Academy @ Hunting Park',
    'Day, Anna B. School',
    'DeBurgos, Julia School',
    'De Hostos, Eugenio Maria Charter School',
    'Decatur, Stephen School',
    'Deep Roots Charter School ',
    'Dick, William School',
    'Discovery Charter School',
    'Disston, Hamilton School',
    'Dobson, James School',
    'Duckrey, Tanner School',
    'Dunbar, Paul L. School',
    'Edmonds, Franklin S. School',
    'Elkin, Lewis School',
    'Ellwood School',
    'Emlen, Eleanor C. School',
    'Farrell, Louis H. School',
    'Fell, D. Newlin School',
    'Finletter, Thomas K. School',
    'First Philadelphia Preparatory Charter School',
    'Fitler Academic Plus ',
    'Fitzpatrick, A. L. School',
    'Folk Arts Cultural Treasures Charter School',
    'Forrest, Edwin School',
    'Fox Chase School',
    'Frank, Anne School',
    'Franklin Towne Charter Elementary School',
    'Franklin, Benjamin School',
    'Frederick Douglass Mastery Charter School',
    'Gideon, Edward School',
    'Girard, Stephen School',
    'Global Leadership Academy Charter School',
    'Global Leadership Academy Charter School Southwest at Huey',
    'Gloria Casarez Elementary',
    'Gompers, Samuel School',
    'Green Woods Charter School',
    'Greenberg, Joseph School',
    'Greenfield, Albert M. School',
    'Hackett, Horatio B. School',
    'Hall, Prince School',
    'Hamilton, Andrew School',
    'Harambee Institute of Science and Technology Charter School',
    'Hardy Williams Academy Charter School',
    'Harrington, Avery D. School',
    'Hartranft, John F. School',
    'Henry, Charles W. School',
    'Heston, Edward School',
    'Holme, Thomas School',
    'Hopkinson, Francis School',
    'Houston, Henry H. School',
    'Howe, Julia Ward School',
    'Hunter, William H. School',
    'Independence Charter School',
    'Independence Charter School West',
    'Inquiry Charter School',
    'Jenks Academy Arts & Sciences',
    'Jenks, Abram School',
    'John Hancock Demonstration School',
    'Juniata Park Academy',
    'Kearny, Gen. Philip School',
    'Kelley, William D. School',
    'Kelly, John B. School',
    'Kenderton School',
    'Key, S. Francis School',
    'Keystone Academy Charter School',
    'KIPP North Philadelphia Charter School',
    'KIPP Philadelphia Charter School',
    'KIPP West Philadelphia Charter School',
    'Kirkbride, Eliza B. School',
    'Laboratory Charter School of Communication and Languages',
    'Lamberton, Robert E. School',
    'Lawton, Henry W. School',
    'Lea, Henry C. School',
    'Lindley Academy Charter at Birney',
    'Lingelbach, Anna L. School',
    'Locke, Alain School',
    'Loesche, William H. School',
    'Logan, James School',
    'Longstreth, William C. School',
    'Lowell, James R. School',
    'Ludlow, James R. School',
    'Marshall, John School',
    'Marshall, Thurgood School',
    'MaST Community Charter School III',
    'Mastery Prep Elementary Charter School',
    'Mastery Charter School Cleveland Elementary ',
    'Mastery Charter School at Clymer Elementary ',
    'Mastery Charter School at Douglass',
    'Mastery Charter School at Hardy Williams ',
    'Mastery Charter School at Harrity Elementary',
    'Mastery Charter School Mann Elementary',
    'Mastery Charter School Pastorius Elementary',
    'Mastery Charter School Smedley Elementary',
    'Mastery Charter School Thomas Campus',
    'Mastery Charter School at Wister',
    'Mathematics, Science, and Technology Community Charter School',
    'Mathematics, Science, and Technology Community Charter School II (MaST II)',
    'Mayfair School',
    'McCall, Gen. George A.  School',
    'McCloskey, John F. School',
    'McClure, Alexander K. School',
    'McDaniel, Delaplaine School',
    'McKinley, William School',
    'McMichael, Morton School',
    'Meade, Gen. George G. School',
    'Meredith, William M. School',
    'Mifflin, Thomas School',
    'Mitchell, S. Weir Elementary School',
    'Moffet, John School',
    'Moore, J. Hampton School',
    'Morris, Robert School',
    'Morrison, Andrew J. School',
    'Morton, Thomas G. School',
    'Munoz-Marin, Hon. Luis School',
    'Nebinger, George W. School',
    'New Foundations Charter School',
    'Northeast Community Propel Academy',
    'Northwood Academy Charter School',
    'Olney School',
    'Overbrook Elementary School',
    'Overbrook Educational Center',
    'Pan American Academy Charter School',
    'Pantoja, Antonia Charter School',
    'Patterson, John M. School',
    'Peirce, Thomas M. School',
    'Penn Alexander School',
    'Pennell, Joseph School',
    'Pennypacker, Samuel School',
    'Penrose School',
    'People For People Charter School',
    'Philadelphia Academy Charter School',
    'Philadelphia Hebrew Public Charter School',
    'Philadelphia Montessori Charter School',
    'Philadelphia Performing Arts Charter School: A String Theory Charter School',
    'Pollock, Robert B. School',
    'Potter-Thomas School',
    'Powel, Samuel School',
    'Prince Hall School School',
    'Rhawnhurst School',
    'Rhoads, James School',
    'Rhodes, E. Washington Elementary School',
    'Richmond School',
    'Roosevelt Elementary School',
    'Rowen, William School',
    'Sankofa Freedom Academy Charter School',
    'Sharswood,George School',
    'Shawmont School',
    'Sheppard, Isaac A. School',
    'Solis-Cohen, Solomon School',
    'Southwark School',
    'Southwest Leadership Academy Charter School',
    'Spring Garden School',
    'Spruance, Gilbert School',
    'Stanton, Edwin M. School',
    'Stearne, Allen M. School',
    'Steel, Edward School',
    'Sullivan, James J. School',
    'Tacony Academy Charter School',
    'Taggart, John H. School',
    'Taylor, Bayard School',
    'Universal Alcorn Charter School',
    'Universal Creighton Charter School',
    'Universal Daroff Charter School',
    'Universal Institute Charter School',
    'Vare-Washington School',
    'Waring, Laura W. School',
    'Washington, Martha School',
    'Webster, John H. School',
    'Welsh, John School',
    'West Oak Lane Charter School',
    'West Philadelphia Achievement Charter School',
    'Widener Memorial School',
    'Willard, Frances E. School',
    'Wissahickon Charter School ',
    'Wright, Richard R. School',
    'Ziegler, William H. School']